import {
  useRef,
  useState,
  ChangeEvent,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import NextLink from "next/link";
import {
  InputAdornment,
  IconButton,
  Link,
  Box,
  CircularProgress,
  Typography,
  ClickAwayListener,
} from "@material-ui/core";
import { makeStyles } from "@lib/themes";
import SkeletonImg from "@components/SkeletonImg";
import TextField from "@components/TextField";
import { ITheme } from "@lib/themes/types";
import fetcher from "@lib/fetcher";
import useSWR from "swr";
import IconClose from "@public/close.svg";
import IconSearch from "@public/search.svg";
import cx from "classnames";
import { useRouter } from "next/router";
import { appScrollBar } from "@lib/utils/cssUtils";
import { addUnderscore } from "@lib/utils";

const useStyles = makeStyles((theme: ITheme, props) => ({
  search: {
    marginLeft: 0,

    "& .MuiInputBase-root": {
      // borderBottomLeftRadius: props.isResultsVisible
      //   ? 0
      //   : theme.shape.borderRadius,
      // borderBottomRightRadius: props.isResultsVisible
      //   ? 0
      //   : theme.shape.borderRadius,
      width: `${props.width ? props.width : "212px"} !important`,
      maxWidth: `${props.width ? props.width : "212px"} !important`,
    },
    "& label": {
      transform: "translate(40px, 12px)",
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
    },
  },
  results: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    width: props.width ? props.width : 212,
    border: `1px solid ${theme.palette.input.selected.border}`,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    borderTopColor: "transparent",
    maxHeight: props.maxHeight ? props.maxHeight : "50vh",
    overflow: "auto",
    zIndex: 100,
    ...appScrollBar(theme),

    "& > div:not(:last-child):after": {
      content: "''",
      backgroundColor: theme.palette.divider,
      position: "absolute",
      left: theme.spacing(2),
      right: theme.spacing(2),
      bottom: 0,
      height: 1,
    },
  },
  item: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1.5),
    position: "relative",
    "&.selected": {
      backgroundColor: theme.palette.divider,
    },
  },
  link: {
    display: "flex",
  },
  disabledText: {
    color: theme.palette.text.disabled,
  },
}));

const useInputStyles = makeStyles((theme: ITheme) => ({
  positionStart: {
    marginLeft: -4,
    marginRight: 4,
    marginTop: "0 !important",
    "& path": {
      fill: theme.palette.gray.main,
    },
  },
  positionEnd: {
    marginRight: -6,
    "& path": {
      fill: theme.palette.gray.main,
    },
  },
}));

interface SearchProps {
  onClear?: () => void;
  maxHeight?: string;
  width?: string;
}

export function Search({ onClear, maxHeight, width }: SearchProps) {
  const inputRef = useRef<HTMLInputElement>();

  const inputClasses = useInputStyles();

  const [searchResult, setSearchResult] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [isInputFocused, setInputFocused] = useState(false);
  const [cursorPos, setCursorPos] = useState(-1);
  const router = useRouter();

  useSWR(keyword.length >= 2 ? `/api/search?text=${keyword}` : null, fetcher, {
    onSuccess: (data) => {
      setSearchResult(data);
      setLoading(false);
    },
    onError: () => setLoading(false),
    revalidateOnFocus: false,
  });

  const isResultsVisible = useMemo(() => {
    return isInputFocused && keyword.length >= 1 && !isLoading && searchResult;
  }, [keyword.length, isLoading, searchResult, isInputFocused]);

  const classes = useStyles({ isResultsVisible, maxHeight, width });

  useEffect(() => {
    if (keyword.length < 1) {
      setSearchResult(undefined);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [keyword]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
    setCursorPos(-1);
  };

  const handleClose = () => {
    if (onClear) {
      onClear();
    }
    setKeyword("");
  };

  const endAdornment = useMemo(() => {
    if (isLoading) {
      return (
        <Box width={24} height={24}>
          <CircularProgress size={24} color="primary" />
        </Box>
      );
    } else if (keyword.length > 0) {
      return (
        <InputAdornment position="end" classes={inputClasses}>
          <IconButton
            color="default"
            size="small"
            aria-label="clear search"
            onClick={handleClose}>
            <IconClose />
          </IconButton>
        </InputAdornment>
      );
    } else {
      return undefined;
    }
  }, [keyword.length, inputClasses, isLoading, onClear]);

  const onClickAway = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.blur();
      setInputFocused(false);
      handleClose();
    }
  }, [inputRef]);

  const onClickLink = useCallback(() => {
    setInputFocused(false);
    setKeyword("");
    setSearchResult(undefined);
  }, []);

  useEffect(() => {
    setKeyword("");
  }, [router.pathname, router.asPath]);

  const getItem = (i: number) => {
    const totalLen =
      searchResult?.assets?.length + searchResult?.exchanges?.length;
    if (i < 0 || i > totalLen - 1) return null;
    if (i > searchResult?.assets?.length - 1) {
      return searchResult?.exchanges[i - searchResult?.assets?.length];
    } else {
      return searchResult?.assets[i];
    }
  };

  const handleKeyDown = (e: {
    keyCode: number;
    preventDefault: () => void;
  }) => {
    const totalLen =
      searchResult?.assets?.length + searchResult?.exchanges?.length;
    if (!searchResult || totalLen === 0) {
      return;
    }

    // Arrow Up
    if (e.keyCode === 38) {
      e.preventDefault();
      setCursorPos(Math.max(-1, cursorPos - 1));
    }
    // Arrow Down
    else if (e.keyCode === 40) {
      e.preventDefault();
      setCursorPos(Math.min(totalLen - 1, cursorPos + 1));
    }
    // Enter
    else if (e.keyCode === 13) {
      // Navigate corresponding asset page.
      if (cursorPos === -1) return;
      const item = getItem(cursorPos);
      if (!item) return;
      router.push(`/asset/${addUnderscore(item.luner_coin_title)}`);
      onClickLink();
      onClickAway();
    }
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box>
        <TextField
          label="Search"
          inputRef={inputRef}
          value={keyword}
          className={classes.search}
          onChange={handleSearchChange}
          autoFocus={true}
          onFocus={() => {
            setInputFocused(true);
            setCursorPos(-1);
          }}
          onKeyDown={handleKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" classes={inputClasses}>
                <IconSearch />
              </InputAdornment>
            ),
            endAdornment,
          }}
        />
        {isResultsVisible && (
          <Box className={classes.results}>
            {searchResult.assets?.length > 0 && (
              <>
                <Box className={classes.item}>
                  <Typography variant="body1">Assets</Typography>
                </Box>
                {searchResult.assets.map((item, i: number) => (
                  <Box
                    className={cx(classes.item, { selected: i === cursorPos })}
                    key={item.coin_title}>
                    <NextLink href={`/asset/${addUnderscore(item.coin_title)}`}>
                      <Link
                        onClick={onClickLink}
                        className={classes.link}
                        variant="body2"
                        color="inherit"
                        href={`/asset/${addUnderscore(item.coin_title)}`}>
                        <Box
                          display="flex"
                          alignItems="center"
                          style={{ cursor: "pointer" }}>
                          <Box mr={1} height={24} width={24}>
                            <SkeletonImg
                              width={24}
                              height={24}
                              src={item.img_url}
                              skeletonProps={{ variant: "circular" }}
                              alt={`${item.coin_title} (${item.coin_symbol}) logo`}
                            />
                          </Box>
                          {item.coin_title}
                          <Box
                            className={classes.disabledText}
                            ml={0.5}>{`(${item.coin_symbol})`}</Box>
                        </Box>
                      </Link>
                    </NextLink>
                  </Box>
                ))}
              </>
            )}
            {searchResult.exchanges?.length > 0 && (
              <>
                <Box className={classes.item}>
                  <Typography variant="body1">Exchanges</Typography>
                </Box>
                {searchResult.exchanges.map((item, i: number) => (
                  <Box
                    className={cx(classes.item, {
                      selected: i + searchResult.assets?.length === cursorPos,
                    })}
                    key={item.exchange_title}>
                    <NextLink
                      href={`/exchange/${addUnderscore(item.exchange_title)}`}>
                      <Link
                        onClick={onClickLink}
                        variant="body2"
                        color="inherit"
                        href={`/exchange/${addUnderscore(
                          item.exchange_title,
                        )}`}>
                        <Box
                          display="flex"
                          alignItems="center"
                          style={{ cursor: "pointer" }}>
                          <Box mr={1} height={24} width={24}>
                            <SkeletonImg
                              width={24}
                              height={24}
                              src={item.logo_url}
                              skeletonProps={{ variant: "circular" }}
                              alt={`${item.exchange_title} logo`}
                            />
                          </Box>
                          {item.exchange_title}
                        </Box>
                      </Link>
                    </NextLink>
                  </Box>
                ))}
              </>
            )}
            {searchResult.assets.length === 0 &&
              searchResult.exchanges.length === 0 && (
                <Box className={classes.item}>
                  <Typography variant="body2" className={classes.disabledText}>
                    No Results
                  </Typography>
                </Box>
              )}
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
}
