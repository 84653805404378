import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@lib/themes";
// import FooterIcon from "@public/footer-pattern.svg"
// import FooterIconMobile from "@public/footer-pattern-mobile.svg"

const useStyles = makeStyles(() => {
  return {
    root: {
      // background: theme.palette.background.main,
      // backgroundColor: theme.palette.background.secondBackground,
      // backdropFilter: "blur(608px)",
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
    icon: {
      width: "100%",
      objectFit: "contain",
      height: "100%",
      objectPosition: "bottom right",
      // "& stop": {
      //   stopColor: theme.palette.footer.gradient,
      // },
      // "& stop:nth-child(1)": {
      //   stopOpacity: opacity[0],
      // },
      // "& stop:nth-child(2)": {
      //   stopOpacity: opacity[1],
      // },
      // "& stop:nth-child(3)": {
      //   stopOpacity: opacity[2],
      // },
    },
  };
});

export default function FooterPattern() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down("sm"));
  const Icon = `/footer-pattern-${theme.palette.mode}.svg`;
  const mobileIcon = `/footer-pattern-mobile-${theme.palette.mode}.svg`;

  // console.log(FooterIconMobile, FooterIcon);

  return (
    <Box className={classes.root}>
      {isMobile ? (
        <img className={classes.icon} src={mobileIcon} />
      ) : (
        <img className={classes.icon} src={Icon} />
      )}
    </Box>
  );
}
