export const getExchangeSlug = (exchange) => {
  if (exchange === "Binance Jersey") {
    return "binanceje";
  } else if (exchange === "huobiglobal" || exchange === "Huobi Global") {
    return "huobi";
  } else {
    return exchange.replace(/[.\s]+/g, "").toLowerCase();
  }
};

export const getPairName = (exchange, base, quote, delimiter = "-") => {
  return [getExchangeSlug(exchange), base, quote].join(delimiter);
};

export const getExchangeAssetName = (exchange, asset) => {
  return `${getExchangeSlug(exchange)}-${asset}`;
};

export const splitPairName = (pairName, delimiter = "-") => {
  const tokens = pairName.split(delimiter);

  return {
    exchange: tokens[0],
    base: tokens[1],
    quote: tokens[2],
  };
};
